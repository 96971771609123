import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import useBrands from "../../hooks/useBrands"
import useMarkets from "../../hooks/useMarkets"
import useNav from "../../hooks/useNav"
import LocalizedLink from "../../i18n/localized-link"
import { useTranslation } from "../../i18n/translate"
import "./footer.sass"

const Footer = () => {
  // Translation Function
  const { t } = useTranslation()

  // Query all Brands
  const brands = useBrands()

  // Query all Markets
  const markets = useMarkets()

  const legalMenu = Object.assign(useNav({ uid: "legal" }), {
    url: useNav({ uid: "imprint" }).url,
  })

  legalMenu.children.push({
    translationKey: "MISC-CHANGE_COOKIE_SETTINGS",
    callback: () => window.brynConsent.openSettings(),
  })

  // Define Footer Items
  const items = [
    {
      translationKey: "MENU-MARKETS_PAGE_TITLE",
      url: "/markets/",
      extraLargeItem: true,
      children: markets
        .filter((market) => !market.hidden)
        .map((market) => {
          return {
            translatedString: t(market.name.key),
            url: market.getDetailPageUrl(),
          }
        })
        .sort((a, b) => {
          return a.translatedString.localeCompare(b.translatedString)
        }),
    },
    {
      translationKey: "MENU-PRODUCTS_PAGE_TITLE",
      url: "/products/",
      largeItem: true,
      children: brands
        .filter((brand) => !brand.hidden)
        .map((brand) => {
          return {
            translatedString: t(brand.name.key),
            url: brand.getDetailPageUrl(),
          }
        })
        .sort((a, b) => {
          return a.translatedString.localeCompare(b.translatedString)
        }),
    },
    useNav({ uid: "sustainability" }),
    useNav({ uid: "munzing-group" }),
    useNav({ uid: "downloads" }),
    useNav({ uid: "career" }),
    {
      translationKey: "MENU-CONTACT_PAGE_TITLE",
      url: "/contact",
      children: [{ translationKey: "MENU-FIND_A_CONTACT", url: "/contact" }],
    },
    legalMenu,
  ].filter((nav) => !nav.hidden)

  return (
    <footer className={"_fp-footer"}>
      <div className="_fp-global-container">
        <nav className="_fp-footer__nav">
          {items.map((item, index) => (
            <ul
              key={`footer-nav-${index}`}
              className={classNames(
                "_fp-footer__link-block",
                { "_fp-footer__link-block--large": item.largeItem },
                { "_fp-footer__link-block--extra-large": item.extraLargeItem },
              )}
            >
              <li className={"_fp-footer__link-block__title"}>
                <LocalizedLink className={"_fp-link"} to={item.url}>
                  <strong>
                    {t(item.translationKey) || item.translatedString}
                  </strong>
                </LocalizedLink>
              </li>
              {item.children && (
                <>
                  {item.children.map((item, childIndex) => (
                    <li key={`footer-nav-${index}-child-${childIndex}`}>
                      {!item.callback && (item.url || item.callback) && (
                        <LocalizedLink
                          activeClassName={"_fp-link--active"}
                          className={"_fp-link _fp-link--small _fp-link--light"}
                          to={item.url}
                        >
                          {t(item.translationKey) || item.translatedString}
                        </LocalizedLink>
                      )}
                      {item.callback && !item.url && (
                        <button
                          className={"_fp-link _fp-link--small _fp-link--light"}
                          onClick={() => item.callback()}
                        >
                          {t(item.translationKey) || item.translatedString}
                        </button>
                      )}
                    </li>
                  ))}
                </>
              )}
            </ul>
          ))}
        </nav>
        <div className="_fp-footer__bottom-row">
          <p className={"_fp-text _fp-text--small"}>
            {t("MISC-WEBSITE_COPYRIGHT", { year: new Date().getFullYear() })}
          </p>
          <p className={"_fp-attribution _fp-text _fp-text--small"}>
            <a
              className={"_fp-link _fp-link--small _fp-link--light"}
              target={"_blank"}
              rel="noreferrer"
              href="https://foolsparadise.de/"
            >
              {t("MISC-WEBSITE_CREATED_BY", {
                creator: "foolsparadise® Werbeagentur",
              })}
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
